<template>
  <v-app id="root" class="overflow-hidden">
    <v-app-bar
      app
      fixed
      height="64"
      color="white"
      elevation="0"
      elevate-on-scroll
    >
      <img height="32" src="/ae_logo_horizontal_light.png" />
      <v-spacer />
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-btn text rounded class="text-capitalize ma-0" v-on="on">
            <v-avatar
              v-if="user.photoURL"
              height="40"
              width="40"
              min-width="40"
            >
              <v-img :src="user.photoURL" height="40" width="40" />
            </v-avatar>
            <v-icon v-else dark>mdi-account-circle</v-icon>
            <div class="ml-3">{{ user.displayName }}</div>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>Edit Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <div class="pattern-ox" />
    <dashboard-core-footer />

    <NotificationContainer />
    <UpdateApp />
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'LayoutDefault',
  components: {
    DashboardCoreFooter: () => import('../../components/core/Footer'),
    NotificationContainer: () =>
      import('@/components/core/NotificationContainer'),
    UpdateApp: () => import('@/components/core/UpdateApp'),
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    ...mapActions('user', ['singOut']),
    logout() {
      this.singOut();
      this.$router.push('/user/login');
    },
  },
};
</script>
